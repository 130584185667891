@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

/* If this works we can look to move into the tailwind config or DS tailwind config as a proper default */
.h-screen-custom {
  height: 100vh;
  height: 100dvh;
}

div[role="dialog"] {
  @apply outline-none;
}

.clip-path-header-container {
  filter: url("#flt_tag");
}

.clip-path-header {
  /* clip-path: polygon(100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%, 0 0); */
  clip-path: polygon(0 25%, 25% 1%, 100% 0%, 100% 75%, 75% 100%, 0% 100%);
}

.flt_svg {
  visibility: hidden;
  position: absolute;
  width: 0px;
  height: 0px;
}

/* NOTE: Hide breadcrumb on step 4 on short screens, it doesn't do anything at this point anyways, basic implementation */
.generate-dialog [data-breadcrumb="4"] {
  display: none;

  @media (min-height: 660px) {
    display: block;
  }
}

.leadgen-dialog {
  grid-template-rows: auto auto 1fr;
  max-width: theme("width.contained-xl");

  @screen md {
    width: calc(100% - theme("spacing.32") * 2);
  }

  @screen lg {
    width: calc(100% - theme("spacing.48") * 2);
  }

  @screen xl {
    width: calc(100% - theme("spacing.80") * 2);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
